import React, { lazy, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { themeChange } from "theme-change";
import { useAuth } from "./app/auth";
import initializeApp from "./app/init";
import { H } from "highlight.run";
import { onValue, ref as dbRef } from "firebase/database";

// Importing pages
const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Register = lazy(() => import("./pages/Register"));
const Documentation = lazy(() => import("./pages/Documentation"));

// Initializing different libraries
initializeApp();

function App() {
  // Check for login and initialize axios
  const auth = useAuth();

  useEffect(() => {
    // 👆 daisy UI themes initialization
    themeChange(false);
  }, []);

  useEffect(() => {
    if (auth?.user?.["screenName"] || auth?.user?.["displayName"]) {
      listenToSubscriptionData();
    }

    // eslint-disable-next-line
  }, [auth?.user]);

  const listenToSubscriptionData = async () => {
    //listen to the subscription data from realtime user database
    if (auth && auth.user) {
      const subscriptionRef = dbRef(
        auth?.rtdb,
        "subscription/" + auth?.user.localId
      );
      onValue(subscriptionRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          try {
            const date = new Date(data["createdAt"]);
            const createdAt =
              date.getFullYear() +
              "-" +
              (date.getMonth() + 1) +
              "-" +
              date.getDate();
            H.identify(
              auth?.user?.["screenName"] || auth?.user?.["displayName"],
              {
                firebaseId: auth?.user?.["localId"],
                status: data["status"],
                createdAt: createdAt,
              }
            );
          } catch (error) {
            console.log("error : ", error);
          }
        }
      });
    }
  };

  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} />
          <Route path="/documentation" element={<Documentation />} />

          {/* Place new routes over this */}
          <Route path="/app/*" element={<Layout />} />

          <Route
            path="*"
            element={
              <Navigate to={auth?.user ? "/app/dashboard" : "/login"} replace />
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
